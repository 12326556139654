export const VERIFY_SIMPLIFY_LOGIN_EMAIL_START = 'VERIFY_SIMPLIFY_LOGIN_EMAIL_START';
export const VERIFY_SIMPLIFY_LOGIN_EMAIL_FAIL = 'VERIFY_SIMPLIFY_LOGIN_EMAIL_FAIL';
export const VERIFY_SIMPLIFY_LOGIN_EMAIL_SUCCESS = 'VERIFY_SIMPLIFY_LOGIN_EMAIL_SUCCESS';

export const VERIFY_SIMPLIFY_LOGIN_OTP_START = 'VERIFY_SIMPLIFY_LOGIN_OTP_START';
export const VERIFY_SIMPLIFY_LOGIN_OTP_FAIL = 'VERIFY_SIMPLIFY_LOGIN_OTP_FAIL';
export const VERIFY_SIMPLIFY_LOGIN_OTP_SUCCESS = 'VERIFY_SIMPLIFY_LOGIN_OTP_SUCCESS';

export const SIMPLIFY_LOGIN_SET_PASSWORD_ACTION_START = 'SIMPLIFY_LOGIN_SET_PASSWORD_ACTION_START';
export const SIMPLIFY_LOGIN_SET_PASSWORD_ACTION_FAIL = 'SIMPLIFY_LOGIN_SET_PASSWORD_ACTION_FAIL';
export const SIMPLIFY_LOGIN_SET_PASSWORD_ACTION_SUCCESS = 'SIMPLIFY_LOGIN_SET_PASSWORD_ACTION_SUCCESS';
