import React, { useState } from 'react';
import config from '../../config/config.json';
import CustomLink from '../ui/CustomLink';
import { EXTERNAL_LINKS, MUI_COLORS, PRODUCT_PAGES_URL } from '../../constants/enum';
import ApkDownloadWrapper from '../Wrappers/ApkDownloadWrapper';
import { handleReDirectToStore } from '../../helper/helpers';
import { ENDPOINTS } from '../../service/end-points';
import PropTypes from 'prop-types';
import FooterAwards from './FooterAwards';
function Footer({ isHomePage, removeTopNavigation }) {
  const [appleStoreIconColors, setAppleStoreIconColors] = useState({
    stroke: `${MUI_COLORS.DARK_GRAY_MAIN}`,
    fill: `${MUI_COLORS.DARK_GRAY_MAIN}`
  });
  const [playStoreIconColors, setPlayStoreIconColors] = useState({
    stroke: `${MUI_COLORS.DARK_GRAY_MAIN}`,
    fill: `${MUI_COLORS.DARK_GRAY_MAIN}`
  });
  const [ApkIconColors, setApkIconColors] = useState({
    stroke: `${MUI_COLORS.DARK_GRAY_MAIN}`,
    fill: `${MUI_COLORS.DARK_GRAY_MAIN}`
  });
  const currentYear = new Date().getFullYear();

  const handleMouseEnter = (stateName, setStateName) => {
    setStateName({ ...stateName, stroke: `${MUI_COLORS.CORAL}`, fill: `${MUI_COLORS.CORAL}` });
  };
  const handleMouseLeave = (stateName, setStateName) => {
    setStateName({ ...stateName, stroke: `${MUI_COLORS.DARK_GRAY_MAIN}`, fill: `${MUI_COLORS.DARK_GRAY_MAIN}` });
  };
  return (
    <>
      <footer
        className={`pt-10 footer ${
          isHomePage || removeTopNavigation
            ? 'max-w-6xl tab-container-padding mx-auto px-4 md:px-0'
            : `max-w-6xl px-4 md:px-0 mx-auto`
        }`}
      >
        <div className="flex w-full md:flex-row flex-col gap-10 justify-between md:mx-auto items-center md:mb-10 mb-5 md:mt-0 mt-8">
          <CustomLink to="/">
            <img src={'../../../statrys-logo.svg'} loading="lazy" width="147" height="40" alt="Statrys Logo" />
          </CustomLink>
          <div className="flex flex-col md:flex-row gap-2 justify-between">
            <div
              onMouseEnter={() => handleMouseEnter(appleStoreIconColors, setAppleStoreIconColors)}
              onMouseLeave={() => handleMouseLeave(appleStoreIconColors, setAppleStoreIconColors)}
              className="cursor-pointer"
              onClick={() => handleReDirectToStore(EXTERNAL_LINKS.APPLE_STORE)}
            >
              <img src="../../../app-store.svg" loading="lazy" width="152" height="40" alt="app-store" />
            </div>
            <div
              onMouseEnter={() => handleMouseEnter(playStoreIconColors, setPlayStoreIconColors)}
              onMouseLeave={() => handleMouseLeave(playStoreIconColors, setPlayStoreIconColors)}
              className="cursor-pointer"
              onClick={() => handleReDirectToStore(EXTERNAL_LINKS.GOOGLE_STORE)}
            >
              <img src="../../../play-store.svg" loading="lazy" width="152" height="40" alt="play-store" />
            </div>
            <ApkDownloadWrapper>
              <div
                onMouseEnter={() => handleMouseEnter(ApkIconColors, setApkIconColors)}
                onMouseLeave={() => handleMouseLeave(ApkIconColors, setApkIconColors)}
                className="cursor-pointer"
              >
                <img src="../../../apk-download.svg" loading="lazy" width="152" height="40" alt="apk-download" />
              </div>
            </ApkDownloadWrapper>
          </div>
        </div>
        <div className="footer-links flex flex-wrap md:items-start justify-between md:gap-6">
          <div className="div-link">
            <p className="text-bold mb-4 md:mb-2 footer-title">Business Account</p>
            <ul>
              <li>
                <CustomLink to="/business-account">Hong Kong Business Account</CustomLink>
              </li>
              <li>
                <CustomLink to="/international-and-local-payments">International and local payments</CustomLink>
              </li>
              <li>
                <CustomLink to="/uk/payment-card">Payment Cards</CustomLink>
              </li>
              <li>
                <CustomLink to="/forex">Advanced FX</CustomLink>
              </li>
              <li>
                <CustomLink to="/mobile-app">Mobile App</CustomLink>
              </li>
              <li>
                <CustomLink to="/business-account/rewards">Partner perks</CustomLink>
              </li>
              <li>
                <CustomLink to="/get-rewarded">Get Rewarded</CustomLink>
              </li>
              <li>
                <CustomLink to={ENDPOINTS.INTEGRATION_XERO}>Xero Integration</CustomLink>
              </li>
              <li>
                <CustomLink to={ENDPOINTS.PRICING}>Pricing</CustomLink>
              </li>
            </ul>
          </div>
          <div className="div-link">
            <p className="text-bold mb-4 md:mb-2 footer-title">
              {' '}
              <div className="flex items-center">Company Creation</div>
            </p>
            <ul>
              <li>
                <CustomLink to={ENDPOINTS?.HONG_KONG_LANDING_PAGE}>Hong Kong Company Registration</CustomLink>
              </li>
              <li>
                <CustomLink to={ENDPOINTS?.CHANGE_COMPANY_SECRETARY}>Change Company Secretary</CustomLink>
              </li>
              <li>
                <CustomLink to={ENDPOINTS?.SINGAPORE_LANDING_PAGE}>Singapore Company Registration</CustomLink>
              </li>
            </ul>
            <p className="mb-4 md:mb-2 mt-4 footer-title">
              <p className="flex items-center text-bold">Accounting</p>
            </p>
            <ul>
              <li>
                <CustomLink to={ENDPOINTS.ACCOUNTING}>Accounting Services</CustomLink>
              </li>
              <li>
                <CustomLink to={ENDPOINTS.INVOICES}>Invoice Management</CustomLink>
              </li>
            </ul>
            <p className="text-bold mb-4 md:mb-2 mt-4 footer-title">Tools</p>
            <ul>
              <li>
                <CustomLink to="/tools/paypal-fee-calculator">PayPal Fee Calculator</CustomLink>
              </li>
              <li>
                <CustomLink to="/tools/stripe-fee-calculator">Stripe Fee Calculator</CustomLink>
              </li>
            </ul>
          </div>
          <div className="div-link">
            <p className="text-bold mb-4 md:mb-2 footer-title">Resources</p>
            <ul>
              <li>
                <CustomLink to="/guides" activeClassName="active">
                  Business Guides
                </CustomLink>
              </li>
              <li>
                <CustomLink to="/blog">Blog</CustomLink>
              </li>
              <li>
                <CustomLink to="/reviews/business-bank-accounts">Business Account Reviews</CustomLink>
              </li>
              <li>
                <CustomLink to="/guides/videos">Video Guides</CustomLink>
              </li>
              <li>
                <CustomLink to="/reviews/business-account-quiz">Business Account Quiz</CustomLink>
              </li>
              <li>
                <CustomLink to={ENDPOINTS.OFFSHORE_ELIGIBILITY_QUIZ}>Offshore Eligibility Quiz</CustomLink>
              </li>
              <li>
                <CustomLink to="/comparisons">Comparisons</CustomLink>
              </li>
              <li>
                <CustomLink to={`${PRODUCT_PAGES_URL.COMPANY_SECRETARY_URL}`}>Company Secretary Reviews</CustomLink>
              </li>
              <li>
                <CustomLink to="/resources/whitepapers">Whitepapers & E-books</CustomLink>
              </li>
              <li>
                <CustomLink to="/resources/newsletters">Industry Newsletters</CustomLink>
              </li>
              <li>
                <CustomLink to="/glossary">Glossary</CustomLink>
              </li>
            </ul>
          </div>
          <div className="div-link">
            <p className="text-bold mb-4 md:mb-2 footer-title">Company</p>
            <ul>
              <li>
                <CustomLink to="/about-us" activeClassName="active">
                  About Us
                </CustomLink>
              </li>
              <li>
                <CustomLink to="/reviews">Customer Reviews</CustomLink>
              </li>
              <li>
                <CustomLink to="/terms-and-conditions">Terms and Conditions</CustomLink>
              </li>
              <li>
                <CustomLink to={PRODUCT_PAGES_URL.PRIVACY_POLICY_URL}>Privacy Policy</CustomLink>
              </li>
              <li>
                <CustomLink to="/faq">FAQs</CustomLink>
              </li>
              <li>
                <CustomLink to="/refer-a-friend">Refer a friend</CustomLink>
              </li>
              <li>
                <CustomLink to={ENDPOINTS.ACCOUNT_SAFETY_TIPS}>Account Safety Tips</CustomLink>
              </li>
            </ul>
          </div>
          <div className="footer-contact-details div-link">
            <p className="text-bold mb-4 md:mb-2 footer-title">Contact</p>
            <ul>
              <li>
                <CustomLink to="/contact" activeClassName="active">
                  Get in touch
                </CustomLink>
              </li>
              <li className="h-4">
                <a href={`tel:${config.phone_number}`}>
                  <div className="flex items-center">
                    <img src="../../../phone.svg" loading="lazy" width="17" height="17" alt="Icon Phone" />
                    <span>{config.phone_number}</span>
                  </div>
                </a>
              </li>

              <li className="h-4">
                <a href={config.whatsapp_link}>
                  <div className="flex items-center">
                    <img src="../../../whatsapp.svg" loading="lazy" width="17" height="17" alt="Icon Whatsapp" />
                    <span>{config.whatsapp}</span>
                  </div>
                </a>
              </li>

              <li className="h-4">
                <a href={config.email_support_link}>
                  <div className="flex items-center">
                    <img src="../../../mailbox.svg" loading="lazy" width="17" height="17" alt="Icon Mailbox" />
                    <span>{config.email_support}</span>
                  </div>
                </a>
              </li>
              <li>
                <div className="flex items-center">
                  <img src="../../../location.svg" loading="lazy" width="16" height="16" alt="location" />
                  <p>
                    {config.location.l1}
                    {config.location.l2}
                  </p>
                </div>
                <div className="ml-auto social-media-container md:gap-0 gap-2 mt-8 flex items-center">
                  <div className="item">
                    <a href={config.social_media.linkedIn} target="_blank" rel="noreferrer">
                      <img src="../../../linkedin.svg" loading="lazy" width="14" height="15" alt="LinkedIn Icon" />
                    </a>
                  </div>

                  <div className="item">
                    <a href={config.social_media.fb} target="_blank" rel="noreferrer">
                      <img src="../../../facebook.svg" loading="lazy" width="16" height="16" alt="Facebook Icon" />
                    </a>
                  </div>

                  <div className="item">
                    <a href={config.social_media.youtube} target="_blank" rel="noreferrer">
                      <img src="../../../youtube.svg" loading="lazy" width="16" height="12" alt="YouTube Icon" />
                    </a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="my-10">
          <FooterAwards />
        </div>
        <div className="mt-7">
          <p className="footer-text whitespace-pre-line  w-full text-gray-300 pt-5 md:pt-0">
            {config.footerText.lineOne}
          </p>
          <p className="footer-text whitespace-pre-line  w-full text-gray-300 pt-5 md:pt-0">
            {config.footerText.lineTwo}
          </p>
          <p className="footer-text whitespace-pre-line  w-full text-gray-300 pt-5 md:pt-0">
            © Copyright {currentYear} Statrys Ltd. All rights reserved.
          </p>
          <p className="footer-text whitespace-pre-line  w-full text-gray-300 pt-5 md:pt-0">
            {config.footerText.lineThree}
          </p>
        </div>
      </footer>
    </>
  );
}
export default Footer;

Footer.propTypes = {
  isHomePage: PropTypes.bool,
  removeTopNavigation: PropTypes.bool
};
