export const FETCH_BUSINESS_ACCOUNT_APPLICATIONS_LIST_START = 'FETCH_BUSINESS_ACCOUNT_APPLICATIONS_LIST_START';
export const FETCH_BUSINESS_ACCOUNT_APPLICATIONS_LIST_FAIL = 'FETCH_BUSINESS_ACCOUNT_APPLICATIONS_LIST_FAIL';
export const FETCH_BUSINESS_ACCOUNT_APPLICATIONS_LIST_SUCCESS = 'FETCH_BUSINESS_ACCOUNT_APPLICATIONS_LIST_SUCCESS';

export const FETCH_USER_APPLICATIONS_ACCESS_STATUS_START = 'FETCH_USER_APPLICATIONS_ACCESS_STATUS_START';
export const FETCH_USER_APPLICATIONS_ACCESS_STATUS_FAIL = 'FETCH_USER_APPLICATIONS_ACCESS_STATUS_FAIL';
export const FETCH_USER_APPLICATIONS_ACCESS_STATUS_SUCCESS = 'FETCH_USER_APPLICATIONS_ACCESS_STATUS_SUCCESS';

export const FETCH_COMPANY_MANAGEMENT_APPLICATIONS_LIST_START = 'FETCH_COMPANY_MANAGEMENT_APPLICATIONS_LIST_START';
export const FETCH_COMPANY_MANAGEMENT_APPLICATIONS_LIST_FAIL = 'FETCH_COMPANY_MANAGEMENT_APPLICATIONS_LIST_FAIL';
export const FETCH_COMPANY_MANAGEMENT_APPLICATIONS_LIST_SUCCESS = 'FETCH_COMPANY_MANAGEMENT_APPLICATIONS_LIST_SUCCESS';

export const FETCH_INVOICE_MANAGEMENT_APPLICATIONS_LIST_START = 'FETCH_INVOICE_MANAGEMENT_APPLICATIONS_LIST_START';
export const FETCH_INVOICE_MANAGEMENT_APPLICATIONS_LIST_FAIL = 'FETCH_INVOICE_MANAGEMENT_APPLICATIONS_LIST_FAIL';
export const FETCH_INVOICE_MANAGEMENT_APPLICATIONS_LIST_SUCCESS = 'FETCH_INVOICE_MANAGEMENT_APPLICATIONS_LIST_SUCCESS';
