import * as actionTypes from './actionTypes';

let initialState = {
  loginSsoUser: {},
  ssoUserInfo: {},
  mfaOtpResponse: {},
  generateMfaNotificationResponse: {},
  verifyMfaNotificationStatusResponse: {},
  verifyMfaOtpResponse: {},
  loading: false,
  error: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SSO_USER_START:
      return { ...state, loading: true };
    case actionTypes.LOGIN_SSO_USER_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.LOGIN_SSO_USER_SUCCESS:
      return { ...state, loginSsoUser: { ...action.loginSsoUser }, loading: false, error: false };

    case actionTypes.GET_SSO_USER_INFO_START:
      return { ...state, loading: true };
    case actionTypes.GET_SSO_USER_INFO_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_SSO_USER_INFO_SUCCESS:
      return { ...state, ssoUserInfo: { ...action.ssoUserInfo }, loading: false, error: false };


    case actionTypes.GENERATE_MFA_OTP_START:
      return { ...state, loading: true };
    case actionTypes.GENERATE_MFA_OTP_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GENERATE_MFA_OTP_SUCCESS:
      return { ...state, mfaOtpResponse: { ...action.mfaOtpResponse }, loading: false, error: false };

    case actionTypes.GENERATE_MFA_NOTIFICATION_START:
      return { ...state, loading: true };
    case actionTypes.GENERATE_MFA_NOTIFICATION_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GENERATE_MFA_NOTIFICATION_SUCCESS:
      return {
        ...state,
        generateMfaNotificationResponse: { ...action.generateMfaNotificationResponse },
        loading: false,
        error: false
      };

    case actionTypes.VERIFY_MFA_NOTIFICATION_STATUS_START:
      return { ...state, loading: true };
    case actionTypes.VERIFY_MFA_NOTIFICATION_STATUS_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.VERIFY_MFA_NOTIFICATION_STATUS_SUCCESS:
      return {
        ...state,
        verifyMfaNotificationStatusResponse: { ...action.verifyMfaNotificationStatusResponse },
        loading: false,
        error: false
      };

    case actionTypes.VERIFY_MFA_OTP_START:
      return { ...state, loading: true };
    case actionTypes.VERIFY_MFA_OTP_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.VERIFY_MFA_OTP_SUCCESS:
      return { ...state, verifyMfaOtpResponse: { ...action.verifyMfaOtpResponse }, loading: false, error: false };

    default:
      return state;
  }
};
export default reducer;
