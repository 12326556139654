import * as actionTypes from './actionTypes';

let initialState = {
  generateResetPasswordOtp: {},
  verifySimplifyLoginEmailLoading: false,
  verifySimplifyLoginOtpLoading: false,
  simplifyLoginSetPasswordLoading: false,
  error: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VERIFY_SIMPLIFY_LOGIN_EMAIL_START:
      return { ...state, verifySimplifyLoginEmailLoading: true };
    case actionTypes.VERIFY_SIMPLIFY_LOGIN_EMAIL_FAIL:
      return { ...state, error: action.error, verifySimplifyLoginEmailLoading: false };
    case actionTypes.VERIFY_SIMPLIFY_LOGIN_EMAIL_SUCCESS:
      return {
        ...state,
        verifySimplifyLoginEmailResponse: { ...action.verifySimplifyLoginEmailResponse },
        verifySimplifyLoginEmailLoading: false,
        error: false
      };

    case actionTypes.VERIFY_SIMPLIFY_LOGIN_OTP_START:
      return { ...state, verifySimplifyLoginOtpLoading: true };
    case actionTypes.VERIFY_SIMPLIFY_LOGIN_OTP_FAIL:
      return { ...state, error: action.error, verifySimplifyLoginOtpLoading: false };
    case actionTypes.VERIFY_SIMPLIFY_LOGIN_OTP_SUCCESS:
      return {
        ...state,
        verifySimplifyLoginOtpResponse: { ...action.verifySimplifyLoginOtpResponse },
        verifySimplifyLoginOtpLoading: false,
        error: false
      };

    case actionTypes.SIMPLIFY_LOGIN_SET_PASSWORD_ACTION_START:
      return { ...state, simplifyLoginSetPasswordLoading: true };
    case actionTypes.SIMPLIFY_LOGIN_SET_PASSWORD_ACTION_FAIL:
      return { ...state, error: action.error, simplifyLoginSetPasswordLoading: false };
    case actionTypes.SIMPLIFY_LOGIN_SET_PASSWORD_ACTION_SUCCESS:
      return {
        ...state,
        simplifyLoginSetPasswordResponse: { ...action.simplifyLoginSetPasswordResponse },
        simplifyLoginSetPasswordLoading: false,
        error: false
      };

    default:
      return state;
  }
};
export default reducer;
