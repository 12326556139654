import * as actionTypes from './actionTypes';

let initialState = {
  countriesListData: {},
  testYourAccountQuiz: {},
  loading: false,
  error: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COUNTRIES_LIST_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.GET_COUNTRIES_LIST_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.GET_COUNTRIES_LIST_REQUEST_SUCCESS:
      return { ...state, countriesListData: { ...action.countriesList }, loading: false, error: false };

    case actionTypes.TEST_YOUR_ACCOUNT_QUIZ_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.TEST_YOUR_ACCOUNT_QUIZ_REQUEST_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.TEST_YOUR_ACCOUNT_QUIZ_REQUEST_SUCCESS:
      return { ...state, testYourAccountQuiz: { ...action.testYourAccountQuiz }, loading: false, error: false };

    default:
      return state;
  }
};
export default reducer;
