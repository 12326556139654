import { Link } from 'gatsby';
import React, { useState } from 'react';
import '../../styles/header-navigation.scss';
import BaseText from '../ui/typography/BaseText';
import PropTypes from 'prop-types';
import XMSText from '../ui/typography/XMSText';
import GatsbyImageWrapper from '../ui/GatsByImageWrapper';

export default function NavigationDropDown({ menuItems, needSeparator, separtorPosition, needSeparatorTwo, separtorPositionTwo }) {
  const [onHover, setOnHover] = useState();

  return (
    <div className={`dropdown-container -ml-6`}>
      <div className="dropdown-content-container dropdown-content flex flex-col p-6" onMouseLeave={() => setOnHover()}>
        {menuItems.map((menu, index) => {
          return (
            <React.Fragment key={index}>
              {needSeparator && index === separtorPosition ? (
                <div className="navigation-dropdown-bottom-line my-2"></div>
              ) : null}
              {needSeparatorTwo && index === separtorPositionTwo ? (
                <div className="navigation-dropdown-bottom-line my-2"></div>
              ) : null}
              <Link to={menu?.to} className="hover:no-underline">
                <div
                  onMouseEnter={() => setOnHover(index)}
                  className={`flex flex-row items-center dropdown-item rounded-lg p-4 ${index === onHover ? 'bg-coral-100 ' : ''
                    }`}
                  key={index}
                >
                  <div className="w-8 w-[32px]">
                    <GatsbyImageWrapper
                      image={{
                        url: menu?.icon,
                        width: 32,
                        height: 32
                      }}
                      class="w-[32px]"
                      alt="menu-icon"
                      loading="eager"
                      objectFit="contain"
                    />
                  </div>
                  <BaseText title={menu?.pageName} className="ml-4" fontWeight="text-bold" />
                  {menu?.isNewMenuItem && (
                    <div className="bg-coral-500 rounded-lg ml-2">
                      <XMSText title="New" textColor="text-white" className="px-2 py-1" />
                    </div>
                  )}
                </div>
              </Link>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}

NavigationDropDown.propTypes = {
  menuItems: PropTypes.array,
  needSeparator: PropTypes.bool,
  separtorPosition: PropTypes.number,
  needSeparatorTwo: PropTypes.bool,
  separtorPositionTwo: PropTypes.number
};
