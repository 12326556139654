import * as actionTypes from './actionTypes';

let initialState = {
  userProfileInfo: {},
  loading: false,
  verifyOtpLoading: false,
  initiatePasswordLoading: false,
  resendPasswordLoading: false,
  error: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_PROFILE_INFO_START:
      return { ...state, loading: true };
    case actionTypes.FETCH_USER_PROFILE_INFO_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.FETCH_USER_PROFILE_INFO_SUCCESS:
      return { ...state, userProfileInfo: { ...action.userProfileInfo }, loading: false, error: false };

    case actionTypes.INITIATE_CHANGE_PASSWORD_OTP_START:
      return { ...state, initiatePasswordLoading: true };
    case actionTypes.INITIATE_CHANGE_PASSWORD_OTP_FAIL:
      return { ...state, error: action.error, initiatePasswordLoading: false };
    case actionTypes.INITIATE_CHANGE_PASSWORD_OTP_SUCCESS:
      return {
        ...state,
        initiateChangePasswordOtpResponse: { ...action.initiateChangePasswordOtpResponse },
        initiatePasswordLoading: false,
        error: false
      };

    case actionTypes.VERIFY_CHANGE_PASSWORD_OTP_START:
      return { ...state, verifyOtpLoading: true };
    case actionTypes.VERIFY_CHANGE_PASSWORD_OTP_FAIL:
      return { ...state, error: action.error, verifyOtpLoading: false };
    case actionTypes.VERIFY_CHANGE_PASSWORD_OTP_SUCCESS:
      return {
        ...state,
        verifyChangePasswordOtpResponse: { ...action.verifyChangePasswordOtpResponse },
        verifyOtpLoading: false,
        error: false
      };

    case actionTypes.RESEND_CHANGE_PASSWORD_OTP_START:
      return { ...state, resendPasswordLoading: true };
    case actionTypes.RESEND_CHANGE_PASSWORD_OTP_FAIL:
      return { ...state, error: action.error, resendPasswordLoading: false };
    case actionTypes.RESEND_CHANGE_PASSWORD_OTP_SUCCESS:
      return {
        ...state,
        resendChangePasswordOtpResponse: { ...action.resendChangePasswordOtpResponse },
        resendPasswordLoading: false,
        error: false
      };

    case actionTypes.VERIFY_CHANGE_PHONE_NUMBER_OTP_START:
      return { ...state, verifyOtpLoading: true };
    case actionTypes.VERIFY_CHANGE_PHONE_NUMBER_OTP_FAIL:
      return { ...state, error: action.error, verifyOtpLoading: false };
    case actionTypes.VERIFY_CHANGE_PHONE_NUMBER_OTP_SUCCESS:
      return {
        ...state,
        verifyOtpLoading: false,
        error: false
      };

    default:
      return state;
  }
};
export default reducer;
