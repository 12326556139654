import * as actionTypes from './actionTypes';

let initialState = {
  businessAccountApplicationsList: null,
  companyManagementApplicationsList: null,
  invoiceManagementApplicationsList: null,
  loading: false,
  businessAccountApiLoading: false,
  companyManagementApiLoading: false,
  invoiceManagementApiLoading: false,
  businessAccountApiErrorDetails: null,
  companyManagementApiErrorDetails: null,
  invoiceManagementApiErrorDetails: null,
  businessAccountApiError: false,
  companyManagementApiError: false,
  invoiceManagementApiError: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_APPLICATIONS_ACCESS_STATUS_START:
      return { ...state, loading: true };
    case actionTypes.FETCH_USER_APPLICATIONS_ACCESS_STATUS_FAIL:
      return { ...state, error: action.error, loading: false };
    case actionTypes.FETCH_USER_APPLICATIONS_ACCESS_STATUS_SUCCESS:
      return {
        ...state,
        userApplicationAccessStatus: { ...action.userApplicationAccessStatus },
        loading: false,
        error: false
      };

    case actionTypes.FETCH_BUSINESS_ACCOUNT_APPLICATIONS_LIST_START:
      return { ...state, businessAccountApiLoading: true };
    case actionTypes.FETCH_BUSINESS_ACCOUNT_APPLICATIONS_LIST_FAIL:
      return {
        ...state,
        businessAccountApiError: true,
        businessAccountApiLoading: false,
        businessAccountApiErrorDetails: action.error
      };
    case actionTypes.FETCH_BUSINESS_ACCOUNT_APPLICATIONS_LIST_SUCCESS:
      return {
        ...state,
        businessAccountApplicationsList: { ...action.businessAccountApplicationsList },
        businessAccountApiLoading: false,
        businessAccountApiError: false,
        businessAccountApiErrorDetails: null
      };

    case actionTypes.FETCH_COMPANY_MANAGEMENT_APPLICATIONS_LIST_START:
      return { ...state, companyManagementApiLoading: true };
    case actionTypes.FETCH_COMPANY_MANAGEMENT_APPLICATIONS_LIST_FAIL:
      return {
        ...state,
        companyManagementApiError: true,
        companyManagementApiLoading: false,
        companyManagementApiErrorDetails: action.error
      };
    case actionTypes.FETCH_COMPANY_MANAGEMENT_APPLICATIONS_LIST_SUCCESS:
      return {
        ...state,
        companyManagementApplicationsList: { ...action.companyManagementApplicationsList },
        companyManagementApiLoading: false,
        companyManagementApiError: false,
        companyManagementApiErrorDetails: null
      };

    case actionTypes.FETCH_INVOICE_MANAGEMENT_APPLICATIONS_LIST_START:
      return { ...state, invoiceManagementApiLoading: true };
    case actionTypes.FETCH_INVOICE_MANAGEMENT_APPLICATIONS_LIST_FAIL:
      return {
        ...state,
        invoiceManagementApiError: true,
        invoiceManagementApiLoading: false,
        invoiceManagementApiErrorDetails: action.error
      };
    case actionTypes.FETCH_INVOICE_MANAGEMENT_APPLICATIONS_LIST_SUCCESS:
      return {
        ...state,
        invoiceManagementApplicationsList: { ...action.invoiceManagementApplicationsList },
        invoiceManagementApiLoading: false,
        invoiceManagementApiError: false,
        invoiceManagementApiErrorDetails: null
      };

    default:
      return state;
  }
};
export default reducer;
