export const LOGIN_SSO_USER_START = 'LOGIN_SSO_USER_START';
export const LOGIN_SSO_USER_FAIL = 'LOGIN_SSO_USER_FAIL';
export const LOGIN_SSO_USER_SUCCESS = 'LOGIN_SSO_USER_SUCCESS';

export const GET_SSO_USER_INFO_START = 'GET_SSO_USER_INFO_START';
export const GET_SSO_USER_INFO_FAIL = 'GET_SSO_USER_INFO_FAIL';
export const GET_SSO_USER_INFO_SUCCESS = 'GET_SSO_USER_INFO_SUCCESS';

export const GENERATE_MFA_OTP_START = 'GENERATE_MFA_OTP_START';
export const GENERATE_MFA_OTP_FAIL = 'GENERATE_MFA_OTP_FAIL';
export const GENERATE_MFA_OTP_SUCCESS = 'GENERATE_MFA_OTP_SUCCESS';

export const GENERATE_MFA_NOTIFICATION_START = 'GENERATE_MFA_NOTIFICATION_START';
export const GENERATE_MFA_NOTIFICATION_FAIL = 'GENERATE_MFA_NOTIFICATION_FAIL';
export const GENERATE_MFA_NOTIFICATION_SUCCESS = 'GENERATE_MFA_NOTIFICATION_SUCCESS';

export const VERIFY_MFA_NOTIFICATION_STATUS_START = 'VERIFY_MFA_NOTIFICATION_STATUS_START';
export const VERIFY_MFA_NOTIFICATION_STATUS_FAIL = 'VERIFY_MFA_NOTIFICATION_STATUS_FAIL';
export const VERIFY_MFA_NOTIFICATION_STATUS_SUCCESS = 'VERIFY_MFA_NOTIFICATION_STATUS_SUCCESS';

export const VERIFY_MFA_OTP_START = 'VERIFY_MFA_OTP_START';
export const VERIFY_MFA_OTP_FAIL = 'VERIFY_MFA_OTP_FAIL';
export const VERIFY_MFA_OTP_SUCCESS = 'VERIFY_MFA_OTP_SUCCESS';
