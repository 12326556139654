import PropTypes from 'prop-types';
import React from 'react';
import '../../../styles/components/buttonWithLoader.scss';

function ButtonWithLoader({ id, title, className, onClick, disabled, isLoading, type = 'button' }) {
  return (
    <button
      id={id}
      type={type}
      onClick={() => onClick && onClick()}
      className={`button-primary ${className}`}
      disabled={disabled}
    >
      <div className="flex align-middle justify-between">
        {isLoading && (
          <div className="mr-2 mt-[2px]">
            <span className="spinner-container"> </span>
          </div>
        )}

        <div>
          <span> {title} </span>
        </div>
      </div>
    </button>
  );
}

export default ButtonWithLoader;

ButtonWithLoader.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool
};
