export const CLIENT_APPLICATION_STATUS = {
  ACTIVE: 'ACTIVE',
  PENDING_REVIEW: 'PENDING_REVIEW',
  APPROVING: 'APPROVING',
  APPROVED: 'APPROVED',
  UNDER_REVIEW: 'UNDER_REVIEW',
  UNFINISHED: 'UNFINISHED'
};

export const PROSPECT_APPLICATION_PROCESS_STATUS = {
  COMPLETED: 'COMPLETED',
  INCOMPLETE: 'INCOMPLETE'
};

export const PROSPECT_APPLICATION_COMPLIANCE_STATUS = {
  PENDING_REVIEW: 'PENDING_REVIEW',
  APPROVED: 'APPROVED',
  APPROVING: 'APPROVING'
};

export const CLIENT_APPLICATION_STATUS_DATA = [
  { status: 'UNDER_REVIEW', displayValue: 'Under Review', backgroundColor: '#e6f2f1' },
  { status: 'UNFINISHED', displayValue: 'Unfinished Application', backgroundColor: '#f9dbd1' }
];

export const CLIENT_APPLICATION_TYPE = {
  BUSINESS_ACCOUNT: 'BUSINESS_ACCOUNT',
  CARD_ACCOUNT: 'CARD_ACCOUNT',
  APPLICATION: 'APPLICATION',
  CARD_APPLICATION: 'CARD_APPLICATION',
  USER_INVITATION: 'USER_INVITATION'
};

export const COMPANY_MANAGEMENT_APPLICATION_STATUS_DATA = [
  { status: 'UNDER_REVIEW', displayValue: 'Under Review', backgroundColor: '#e6f2f1' },
  { status: 'UNFINISHED', displayValue: 'Unfinished Application', backgroundColor: '#f9dbd1' },
  { status: 'EXPIRING_SOON', displayValue: 'License Expiring Soon', backgroundColor: '#ffc730' }
];
