export const FETCH_USER_PROFILE_INFO_START = 'FETCH_USER_PROFILE_INFO_START';
export const FETCH_USER_PROFILE_INFO_FAIL = 'FETCH_USER_PROFILE_INFO_FAIL';
export const FETCH_USER_PROFILE_INFO_SUCCESS = 'FETCH_USER_PROFILE_INFO_SUCCESS';

export const INITIATE_CHANGE_PASSWORD_OTP_START = 'INITIATE_CHANGE_PASSWORD_OTP_START';
export const INITIATE_CHANGE_PASSWORD_OTP_FAIL = 'INITIATE_CHANGE_PASSWORD_OTP_FAIL';
export const INITIATE_CHANGE_PASSWORD_OTP_SUCCESS = 'INITIATE_CHANGE_PASSWORD_OTP_SUCCESS';

export const VERIFY_CHANGE_PASSWORD_OTP_START = 'VERIFY_CHANGE_PASSWORD_OTP_START';
export const VERIFY_CHANGE_PASSWORD_OTP_FAIL = 'VERIFY_CHANGE_PASSWORD_OTP_FAIL';
export const VERIFY_CHANGE_PASSWORD_OTP_SUCCESS = 'VERIFY_CHANGE_PASSWORD_OTP_SUCCESS';

export const RESEND_CHANGE_PASSWORD_OTP_START = 'RESEND_CHANGE_PASSWORD_OTP_START';
export const RESEND_CHANGE_PASSWORD_OTP_FAIL = 'RESEND_CHANGE_PASSWORD_OTP_FAIL';
export const RESEND_CHANGE_PASSWORD_OTP_SUCCESS = 'RESEND_CHANGE_PASSWORD_OTP_SUCCESS';

export const INITIATE_CHANGE_PHONE_NUMBER_OTP_START = 'INITIATE_CHANGE_PHONE_NUMBER_OTP_START';
export const INITIATE_CHANGE_PHONE_NUMBER_OTP_FAIL = 'INITIATE_CHANGE_PHONE_NUMBER_OTP_FAIL';
export const INITIATE_CHANGE_PHONE_NUMBER_OTP_SUCCESS = 'INITIATE_CHANGE_PHONE_NUMBER_OTP_SUCCESS';

export const VERIFY_CHANGE_PHONE_NUMBER_OTP_START = 'VERIFY_CHANGE_PHONE_NUMBER_OTP_START';
export const VERIFY_CHANGE_PHONE_NUMBER_OTP_FAIL = 'VERIFY_CHANGE_PHONE_NUMBER_OTP_FAIL';
export const VERIFY_CHANGE_PHONE_NUMBER_OTP_SUCCESS = 'VERIFY_CHANGE_PHONE_NUMBER_OTP_SUCCESS';

export const RESEND_CHANGE_PHONE_NUMBER_OTP_START = 'RESEND_CHANGE_PHONE_NUMBER_OTP_START';
export const RESEND_CHANGE_PHONE_NUMBER_OTP_FAIL = 'RESEND_CHANGE_PHONE_NUMBER_OTP_FAIL';
export const RESEND_CHANGE_PHONE_NUMBER_OTP_SUCCESS = 'RESEND_CHANGE_PHONE_NUMBER_OTP_SUCCESS';
