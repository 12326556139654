import { COMPANY_REGISTRATION_COUNTRY } from '../constants/enum';
export const ENDPOINTS = {
  COMPANY_REGISTRATION: `${process.env.INCORPORATION_SITE_URL}/signup/start`,
  HONG_KONG_COMPANY_REGISTRATION: `${process.env.INCORPORATION_SITE_URL}/signup/start?country=${COMPANY_REGISTRATION_COUNTRY.HONG_KONG}`,
  SINGAPORE_COMPANY_REGISTRATION: `${process.env.INCORPORATION_SITE_URL}/signup/start?country=${COMPANY_REGISTRATION_COUNTRY.SINGAPORE}`,
  HONG_KONG_LANDING_PAGE: '/hong-kong-company-registration',
  SINGAPORE_LANDING_PAGE: '/singapore-company-registration',
  OPEN_ACCOUNT: '/signup/getstarted',
  BUSINESS_OPEN_ACCOUNT: '/onboarding/signupStart',
  INTEGRATION_XERO: '/integration/xero',
  SELECT_MANAGER: `${process.env.ONBOARDING_BASE_URL}/onboarding/selectManager`,
  PRICING: '/pricing',
  INVOICES: '/invoicing-software',
  INVOICES_GENERATOR: '/tools/invoice-generator',
  BUSINESS_ACCOUNT: '/business-account',
  INTERNATIONAL_AND_LOCAL_PAYMENTS: '/international-and-local-payments',
  PAYMENT_CARD: '/uk/payment-card',
  FOREX: '/forex',
  XERO: '/integration/xero',
  BUSINESS_BANK_ACCOUNTS: '/reviews/business-bank-accounts',
  MOBILE_APP: '/mobile-app',
  LOGOUT: '/logout',
  TEAM: '/team',
  COMPARISONS: '/comparisons',
  UNDER_MAINTENANCE: '/under-maintenance',
  GUIDES_VIDEOS: '/guides/videos',
  OFFSHORE_ELIGIBILITY_QUIZ: '/offshore-eligibility-quiz',
  CHANGE_COMPANY_SECRETARY: '/change-company-secretary',
  CONTACT: '/contact',
  ACCOUNT_SAFETY_TIPS: '/account-safety-tips',
  LOGIN_V2: '/login',
  ENABLE_MFA_SSO: '/enable-phone-mfa',
  VERIFY_MFA_NOTIFICATION_SSO: '/verify-mfa-notification',
  VERIFY_MFA_SSO: '/verify-mfa',
  VERIFY_MFA_OTP_SSO: '/verify-mfa-otp-sso',
  DASHBOARD_V2: '/dashboard',
  QUIZ_RESULT: '/quiz-result',
  TEST_YOUR_ACCOUNT_QUIZ: '/first-business-account',
  MY_PROFILE: '/my-profile',
  RESET_PASSWORD: '/reset-password',
  ONBOARDING_URL: '/signup/getstarted?=jurisdiction',
  UNDER_REVIEW_APPLICATION: '/under-review-application',
  UPDATE_PHONE_NUMBER: '/update-phone-number',
  VERIFY_PHONE_NUMBER_OTP: '/verify-phone-number-otp',
  AUTHENTICATE_USER: '/authenticate-user',
  SIMPLIFY_LOGIN: '/simplify-login',
  SIMPLIFY_LOGIN_PASSWORD: '/simplify-login-password',
  SIMPLIFY_LOGIN_VERIFY_OTP: '/simplify-login-verify-otp',
  ACCOUNTING: '/accounting-services-hong-kong',
  BUSINESS_ACCOUNT_QUIZ: '/reviews/business-account-quiz',
  BUSSINESS_BANK_ACCOUNT: '/reviews/business-bank-accounts'
};
