import React from 'react';
import backgroundImage from '../../images/graphics/blog-rating-card-bg.png';
import PropTypes from 'prop-types';
import ArrowRight from '../../images/icons/arrow-right-coral.svg';
import { useEffect, useState } from 'react';
import { MUI_COLORS } from '../../constants/enum';

const CircularProgress = ({ score, imageUrl }) => {
  const [progressOffset, setProgressOffset] = useState(0);
  const radius = 50;
  const circumference = 2 * Math.PI * radius;

  useEffect(() => {
    const progress = (score / 10) * circumference;
    setTimeout(() => setProgressOffset(circumference - progress), 100);
  }, [score, circumference]);

  return (
    <div
      className="circle-wrapper"
      style={{
        backgroundImage: `url(${imageUrl})`,
        width: '120px',
        height: '120px',
        position: 'relative'
      }}
    >
      <svg className="circle" width="120" height="120">
        <circle
          className="circle-background"
          cx="60"
          cy="60"
          r={radius}
          stroke={MUI_COLORS?.PANTONE}
          strokeWidth="10"
          fill="none"
        />
        <circle
          className="circle-progress"
          cx="60"
          cy="60"
          r={radius}
          stroke="white"
          strokeWidth="10"
          fill="none"
          strokeDasharray={circumference}
          strokeDashoffset={progressOffset}
          transform="rotate(-90 60 60)"
          style={{
            transition: 'stroke-dashoffset 1s ease-in-out'
          }}
        />
      </svg>
      <div className="circle-content absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
        <div className="score text-white text-[40px] font-bold">{score}</div>
      </div>
    </div>
  );
};

CircularProgress.propTypes = {
  average: PropTypes.number.isRequired,
  imageUrl: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired
};
const ProgressBar = ({ label, value, max = 10 }) => {
  const percentage = (value / max) * 100;

  return (
    <div className="flex flex-col">
      <div className="text-gray-300 text-lg text-bold leading-[130%] tracking-[-1px]">{label}</div>
      <div className="flex justify-between items-center">
        <div className="w-[333px] max-w-full h-max-content bg-white border rounded-full">
          <div
            className="h-[8px] bg-green-500 transition-all duration-300 rounded-full"
            style={{ width: `${percentage}%` }}
          ></div>
        </div>

        <span className="text-gray-300 text-xl text-bold leading-[130%] tracking-[-1px] ml-2">{value}</span>
      </div>
    </div>
  );
};
ProgressBar.propTypes = {
  label: PropTypes.string,
  value: PropTypes.number,
  max: PropTypes.number
};
function RatingCard({ items, primary }) {
  const averageScore =
    items?.reduce((sum, item) => {
      return (sum += item.rating);
    }, 0) / items?.length;
  return (
    <div className="border rounded-2xl bg-white w-[720px] max-w-full">
      <div className="flex flex-col sm:flex-row">
        <div
          className="w-full sm:w-[273px] bg-coral-500 bg-bottom bg-contain p-8 rounded-t-2xl sm:rounded-tr-none sm:rounded-l-2xl flex flex-col gap-8"
          style={{
            backgroundImage: `url(${backgroundImage})`
          }}
        >
          <div className="flex flex-col gap-2">
            <div className="text-salmon-500 text-xl text-bold leading-[110%] tracking-[-1px]">
              {primary?.heading?.text}
            </div>
            <h2 className="text-white pt-2 mt-2 text-bold" style={{padding: '0', margin: '0', lineHeight: '100%'}}>
              {primary?.title1?.text}
            </h2>
          </div>
          <CircularProgress score={averageScore} />
        </div>
        <div className="p-8 flex flex-col gap-8 justify-between">
          <div className="flex flex-col gap-2">
            {items?.map((item, index) => (
              <ProgressBar key={index} label={item?.category_name?.text} value={item?.rating} max={10} />
            ))}
          </div>
          {primary?.card_link?.url
            ? primary.card_link.url.trim() !== '' && (
                <p className="flex gap-2 items-center w-[fit-content]">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={primary.card_link.url}
                    className="text-coral-500 text-xl text-bold leading-[120%] tracking-[-0.5px]"
                  >
                    {primary?.card_link_text}
                  </a>
                  <img src={ArrowRight} className="m-0 max-w-6 h-6" />
                </p>
              )
            : null}
        </div>
      </div>
    </div>
  );
}

RatingCard.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  primary: PropTypes.bool
};
export default RatingCard;
